.inputs span {
    font-size: 14px;
}

.LoginButton {
    background-color: rgb(99, 102, 241);
    color: white;
    width: 100%;
    border-radius: 8px;
    padding: 5px;
    height: 40px;
}
.Iconcenter{
    display: flex;
    justify-content: center;
}

