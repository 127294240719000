.columnStyle {
    box-shadow: 0 0 3px rgba(66, 66, 66, 0.5);
    border-radius: 10px;
    padding: 10px;
    width: 480px;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 600px;

}

.buyButton {
    background-color: #7EBA00;
    color:
        #FFFFFF;
    border-radius: 10px;
    font-size: 17px;
    height: 40px;
    width: 100px;
    position: relative;
}

.buyButton a {
    position: relative;
    top: -5px;
}

.buyButton a:hover {
    color: white;
}

.sellButton a:hover {
    color: rgba(0, 0, 0, 0.5);
}

.sellButton a {
    position: relative;
    top: -5px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
}

.headingmain {
    font-weight: 800;
    color:
        #000000;
    font-size: 25px;
}

.spanheading {
    color: #6C5DD3;
    font-weight: 800;
}

.paragraph {
    max-width: 350px;
    font-size: 14px;
    font-weight:
        400;
    color: #6B6B6B;
}

.sameheading {
    color: #7EBA00;
    font-weight: 700;
    font-size: 20px;
}

.samepara {
    color:
        #081735;
    max-width: 250px;
    font-size: 14px;
}

.newbtn {
    border: 1px solid #7EBA00;
    padding: 10px 20px;
    border-radius: 10px;
    color: #7EBA00;
}

.progress {
    width: 100%;
    max-width: 430px;
}

.confirmbtn {
    /* background-color: #7EBA00; */
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    font-weight: 600;
    max-width: 430px;
}

.backbtn {
    background-color: #E6E8F0;
    color: #6B6B6B;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 430px;
    font-weight: 600;
}

.minus p {
    font-weight: 700;
    color: #000000;
}

.headingdetail {
    color: #000000;
    font-weight: 600;
}