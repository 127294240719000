@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif !important;
}

.apexcharts-toolbar {
    display: none !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-track-piece {
    background: #f1f1f1;
}

::-webkit-scrollbar-button {
    display: none;
}

.ant-input-affix-wrapper-lg {
    padding: 12px 12px !important;

}


.loader-language {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: white #0000;
    animation: l1 1s infinite;
}

@keyframes l1 {
    to {
        transform: rotate(.5turn)
    }
}


.brithness-class {
    filter: brightness(4) !important
}

.filter-class {
    filter: invert(1) !important;
}

.line-height {
    line-height: 70px !important;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #94a3b8 !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: rgb(255 255 255 / 65%) !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.753);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}


.bg-dark {
    cursor: pointer;
    font-weight: 600;
    background-color: rgb(99, 102, 241) !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 12px;
    border: none !important;
}

.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder,
.ant-input-number .ant-input-number-input:placeholder-shown {
    color: #8F95B2 !important;
}

.textnowrap {
    text-wrap: nowrap;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
    color: rgb(237 224 224 / 45%) !important;
}



.img-rounded {
    border-bottom-left-radius: 50px;
}