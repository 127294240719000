.border-custom {
    display: inline-block;
    padding: 10px 20px;
    background-color: #E6E8F0;
    color: #000;
    border: 2px solid #7EBA00;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.border-row {
    padding: 10px 20px;
    background-color: #E6E8F0;
    color: #000;
    border-top: 1px solid #7EBA00;
    border-bottom: 1px solid #7EBA00;
    text-decoration: none;
    cursor: pointer;
}